<template>
  <div class="clearfix">
    <ValidationObserver v-slot="{ valid }">
      <ValidationProvider
        rules="required|alpha_spaces|min:3"
        name="nombre(s)"
        v-slot="{ errors }"
      >
        <vs-input
          data-vv-validate-on="blur"
          label-placeholder="Nombre"
          name="displayName"
          placeholder="Nombre(s)"
          :danger-text="errors[0]"
          :danger="!!errors.length"
          v-model="displayName"
          class="w-full"
        />
      </ValidationProvider>

      <ValidationProvider
        rules="required|alpha_spaces|min:3"
        name="apellido(s)"
        v-slot="{ errors }"
      >
        <vs-input
          data-vv-validate-on="blur"
          label-placeholder="Apellidos(s)"
          name="displaySurname"
          placeholder="Apellido(s)"
          :danger-text="errors[0]"
          :danger="!!errors.length"
          v-model="displaySurname"
          class="w-full mt-6"
        />
      </ValidationProvider>
      <ValidationProvider
        rules="required|email|min:3"
        name="correo"
        v-slot="{ errors }"
      >
        <vs-input
          data-vv-validate-on="blur"
          name="email"
          type="email"
          label-placeholder="Correo"
          placeholder="Correo"
          v-model="email"
          :danger-text="errors[0]"
          :danger="!!errors.length"
          class="w-full mt-6"
        />
      </ValidationProvider>

      <ValidationProvider
        rules="required|min:6|max:10"
        name="contraseña"
        vid="password"
        v-slot="{ errors }"
      >
        <vs-input
          ref="password"
          type="password"
          data-vv-validate-on="blur"
          name="password"
          label-placeholder="Contraseña"
          placeholder="Contraseña"
          :danger-text="errors[0]"
          :danger="!!errors.length"
          v-model="password"
          class="w-full mt-6"
        />
      </ValidationProvider>

      <ValidationProvider
        rules="required|min:6|max:10|confirmed:password"
        name="contraseña"
        vid="confirm_password"
        v-slot="{ errors }"
      >
        <vs-input
          type="password"
          data-vv-validate-on="blur"
          data-vv-as="password"
          :danger-text="errors[0]"
          :danger="!!errors.length"
          name="confirm_password"
          label-placeholder="Confirmar contraseña"
          placeholder="Confirmar contraseña"
          v-model="confirm_password"
          class="w-full mt-6"
        />
      </ValidationProvider>

      <ValidationProvider
        rules="required|min:6"
        name="contraseña maestra"
        vid="master_password"
        v-slot="{ errors }"
      >
        <vs-input
          type="password"
          data-vv-validate-on="blur"
          data-vv-as="password"
          :danger-text="errors[0]"
          :danger="!!errors.length"
          name="confirm_password"
          label-placeholder="Contraseña maestra"
          placeholder="Contraseña maestra"
          v-model="master_password"
          class="w-full mt-6"
        />
      </ValidationProvider>

      <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6"
        >Aceptar términos y condiciones.</vs-checkbox
      >
      <vs-button type="border" to="/iniciar-sesion" class="mt-6"
        >Ya tengo cuenta</vs-button
      >
      <vs-button
        class="float-right mt-6"
        @click="registerUserJWt"
        :disabled="!validateForm(valid)"
        >Registrarme</vs-button
      >
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      displayName: '',
      displaySurname: '',
      email: '',
      password: '',
      confirm_password: '',
      isTermsConditionAccepted: true,
      master_password: '',
    };
  },
  computed: {
    validateForm() {
      // return (
      //   !this.errors.any() &&
      //   this.displayName != '' &&
      //   this.email != '' &&
      //   this.password != '' &&
      //   this.confirm_password != '' &&
      //   this.isTermsConditionAccepted === true
      // );
      return (valid) => {
        return (
          valid &&
          this.displayName != '' &&
          this.displaySurname != '' &&
          this.email != '' &&
          this.password != '' &&
          this.confirm_password != '' &&
          this.isTermsConditionAccepted === true
        );
      };
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;

      const payload = {
        userDetails: {
          displayName: this.displayName,
          displaySurname: this.displaySurname,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password,
          masterPassword: this.master_password,
        },
        notify: this.$vs.notify,
      };
      this.$store
        .dispatch('auth/registerUserJWT', payload)
        .then(() => {
          this.$vs.notify({
            title: 'Account Created',
            text: 'You are successfully registered!',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success',
          });
        })
        .catch((e) => {
          if (e.response && e.response.data) {
            this.$vs.notify({
              title: 'Error',
              text: e.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            });
          }
        });
    },
  },
};
</script>
