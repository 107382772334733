var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clearfix"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:3","name":"nombre(s)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"data-vv-validate-on":"blur","label-placeholder":"Nombre","name":"displayName","placeholder":"Nombre(s)","danger-text":errors[0],"danger":!!errors.length},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces|min:3","name":"apellido(s)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"data-vv-validate-on":"blur","label-placeholder":"Apellidos(s)","name":"displaySurname","placeholder":"Apellido(s)","danger-text":errors[0],"danger":!!errors.length},model:{value:(_vm.displaySurname),callback:function ($$v) {_vm.displaySurname=$$v},expression:"displaySurname"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email|min:3","name":"correo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"data-vv-validate-on":"blur","name":"email","type":"email","label-placeholder":"Correo","placeholder":"Correo","danger-text":errors[0],"danger":!!errors.length},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:10","name":"contraseña","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{ref:"password",staticClass:"w-full mt-6",attrs:{"type":"password","data-vv-validate-on":"blur","name":"password","label-placeholder":"Contraseña","placeholder":"Contraseña","danger-text":errors[0],"danger":!!errors.length},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:6|max:10|confirmed:password","name":"contraseña","vid":"confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"type":"password","data-vv-validate-on":"blur","data-vv-as":"password","danger-text":errors[0],"danger":!!errors.length,"name":"confirm_password","label-placeholder":"Confirmar contraseña","placeholder":"Confirmar contraseña"},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:6","name":"contraseña maestra","vid":"master_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"type":"password","data-vv-validate-on":"blur","data-vv-as":"password","danger-text":errors[0],"danger":!!errors.length,"name":"confirm_password","label-placeholder":"Contraseña maestra","placeholder":"Contraseña maestra"},model:{value:(_vm.master_password),callback:function ($$v) {_vm.master_password=$$v},expression:"master_password"}})]}}],null,true)}),_c('vs-checkbox',{staticClass:"mt-6",model:{value:(_vm.isTermsConditionAccepted),callback:function ($$v) {_vm.isTermsConditionAccepted=$$v},expression:"isTermsConditionAccepted"}},[_vm._v("Aceptar términos y condiciones.")]),_c('vs-button',{staticClass:"mt-6",attrs:{"type":"border","to":"/iniciar-sesion"}},[_vm._v("Ya tengo cuenta")]),_c('vs-button',{staticClass:"float-right mt-6",attrs:{"disabled":!_vm.validateForm(valid)},on:{"click":_vm.registerUserJWt}},[_vm._v("Registrarme")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }